@font-face {
  font-family: 'Oswald Medium';
  src: url('assets/fonts/Oswald-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Oswald Light';
  src: url('assets/fonts/Oswald-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web Bold';
  src: url('assets/fonts/TitilliumWeb-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web Light';
  src: url('assets/fonts/TitilliumWeb-Light.ttf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Titillium Web Light';
  font-size: 12pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

@media (max-width: 960px) {
  body {
    font-size: 9pt;
  }
}
